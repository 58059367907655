import {
  createStRecorder,
  getSTColorTextArr,
  getSTWordTextArr
} from './shengtong';
import {
  createXSRecorder,
  getXSColorTextArr,
  getXSWordTextArr
} from './xiansheng';

export const RecorderObj = {
  ST: createStRecorder,
  XS: createXSRecorder
};
export const textColorObj = {
  ST: getSTColorTextArr,
  XS: getXSColorTextArr
};
export const wordColorObj = {
  ST: getSTWordTextArr,
  XS: getXSWordTextArr
};
