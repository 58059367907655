import request from '@/utils/request';
const requestUrl = process.env.VUE_APP_REQUEST_URL;
const loginUrl = process.env.VUE_APP_LOGIN_URL;

// 数据请求
export function returnRequest(type, url, params) {
  if (String(type).toUpperCase() === 'GET') {
    return request({
      url: requestUrl + url,
      method: type,
      params: params
    });
  }
  return request({
    url: requestUrl + url,
    method: type,
    data: params
  });
}

// 登录
export function returnLoginRequest(type, url, params) {
  if (String(type).toUpperCase() === 'GET') {
    return request({
      url: loginUrl + url,
      method: type,
      params: params
    });
  }
  return request({
    url: loginUrl + url,
    method: type,
    data: params
  });
}

// 这里是所有的接口地址
export function userLogin(params) {
  return returnLoginRequest('POST', 'web/login', params);
}

export function userSign(params) {
  return returnLoginRequest('POST', 'web/register', params);
}

// 用户退出登录
export function userLogout(params) {
  return returnLoginRequest('POST', 'user/logout', params);
}

// 获得一级分类
export function getStartPageMenu(params) {
  return returnRequest('get', 'book/get-start-page-menu', params);
}

// 获得二级分类
export function getBookPress(params) {
  return returnRequest('get', 'book/get-press', params);
}

// 获得教材分类
export function getBookList(params) {
  return returnRequest('get', 'book/get-book-list', params);
}
export function getBookCode(params) {
  return returnRequest('post', 'book/activation-code/check', params);
}

// 获得书的详情
export function getBookInfo(params) {
  return returnRequest('get', 'book/get-book-all-chapter', params);
}

export function getBookCatalogList(params) {
  return returnRequest('get', 'book/get-book-catalog-list', params);
}

// 获得游戏详情
export function getQuestionInfo(params) {
  return returnRequest('get', 'question/exam/get-game-task-info', params);
}

// 获得考试用户进度
export function fetchExamRate(url, params) {
  return returnRequest('get', url, params);
}

// 重置考试进度
export function resetExamRate(params) {
  return returnRequest('post', 'question/exam/reset', params);
}

// 听力叙述获得听力填空的问题
export function fillExamBlankData(params) {
  return returnRequest('get', 'question/exam/get-game-task-info', params);
}

// 获取考试报告列表
export function fetchReportList(params) {
  return returnRequest('get', 'question/student/practice', params);
}

// 获取阅读报告列表
export function fetchReadingReportList(params) {
  return returnRequest('get', 'question/student/reading-practice', params);
}

// 获取考试报告详情
export function fetchReportDetail(params) {
  return returnRequest('get', 'question/student/report', params);
}

//获取阅读报告详情
export function fetchReadingReportDetail(params) {
  return returnRequest('get', 'question/student/reading-report', params);
}

// 获得考试玩的详情
export function fetchExamReport(params) {
  return returnRequest('get', 'question/exam/report', params);
}

// 获取游戏列表
export function fetchBookGameList(params) {
  return returnRequest('get', 'question/get-game-list', params);
}

// 获得游戏详情
export function fetchGameTaskInfo(params) {
  return returnRequest('get', 'question/get-game-task-info', params);
}

// 提交保存答案
export function saveUserGameTask(params) {
  return returnRequest('post', 'question/save-user-game-task', params);
}

// 获得进度
export function gameUserQuestionRate(params) {
  return returnRequest('get', 'question/get-user-question-rate', params);
}
// 重置进度
export function resetUserQuestionRate(params) {
  return returnRequest('post', 'question/reset-user-answer-status', params);
}
// 获取用户的阅读分数
export function getReadingRate(params) {
  return returnRequest('get', 'question/get-reading-rate', params);
}

// 重置阅读
export function resetReadingRate(params) {
  return returnRequest('post', 'question/reset-reading-rate', params);
}
