import axios from 'axios';
import { notification } from 'ant-design-vue';
import { getHash, getToken, getCompany } from '../utils/common';
import router from '../router/index';
function getErrorMsg(error) {
  const { fields } = error;
  let errorMsg = '';
  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      const string = fields[key];
      errorMsg += string;
    }
  }
  return errorMsg;
}

function showErrorMsg(msg) {
  notification.error({
    message: msg
  });
}

function request(options) {
  const hashKey = getHash();
  if (!hashKey) {
    return;
  }
  const defaultOptions = {
    headers: {
      Accept: 'application/json',
      'hash-key': hashKey,
      'Content-Type': 'application/json; charset=utf-8',
      language: localStorage.getItem('umi_locale') === 'en-US' ? 'en' : 'zh-CN',
      Authorization: getToken()
    }
  };
  return new Promise((resolve, reject) => {
    axios({
      ...defaultOptions,
      ...options
    })
      .then(response => {
        const { success } = response.data;
        if (!success) {
          const { status, error: innerError } = response.data;
          switch (Number(status)) {
            case 500:
              showErrorMsg(innerError.message);
              break;
            case 401:
              showErrorMsg(innerError.message);
              router.push(`/user/${getCompany()}/login`);
              break;
            default:
              break;
          }
          reject('异常');
          return;
        }
        resolve(response.data);
      })
      .catch(error => {
        const {
          status,
          data: { error: innerError, message }
        } = error.response;
        switch (Number(status)) {
          case 422:
            showErrorMsg(getErrorMsg(innerError));
            break;
          default:
            showErrorMsg(message);
            break;
        }
        reject(error);
      });
  });
}

export default request;
