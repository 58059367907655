import Vue from 'vue';
import Vuex from 'vuex';
import { saveUserGameTask } from '../api/service';
import { RecorderObj } from '../utils/Recorder/index';
import { handleWithUrl, showMyHint } from '../utils/common';
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    themeColor: '#5ACAF4',
    activeColor: '#5ACAF4',
    evalCompany: 'XS',
    // 先声评测插件id  a419, t419
    evalAppId: '',
    // 配置信息需要事先声明，这里暂时用不到
    evalPluginAppId: '',
    evalPluginVersion: '',
    scoreArr: [
      {
        cn_name: '优',
        color: '#7cc99f',
        en_name: 'excellent',
        max: 100,
        min: 85
      },
      { cn_name: '良', en_name: 'great', color: '#f19d2f', min: 70, max: 85 },
      { cn_name: '中', en_name: 'good', color: '#999999', min: 55, max: 69 },
      { cn_name: '差', en_name: 'fair', color: '#dd355d', min: 0, max: 54 }
    ],
    selfAppId: '',
    // 考试题型中是不是第一个类型，
    isFirstGame: false,
    // 是不是第一个类型中的第一个题,
    isFirstQuestion: false,
    // 录音机
    recorder: '',
    // 录音状态
    recorderStatus: false,
    // 播放音频索引
    playAudioIndex: -1,
    // 播放音频状态
    playAudioStatus: false,
    // 播放录音索引
    playRecordIndex: -1,
    // 播放录音状态
    playRecordStatus: false,
    // 录音相关数据
    recordObj: {},
    audioPercent: 0,
    // 麦克风允许状态
    micAllowStatus: false,
    loading: false,
    loadingTips: '',
    // 是1就显示英语，2就显示分数
    evalResultShow: 1,
    // 视频播放进度
    videoProgress: 0,
    // 音频是否自动播放
    audioAutoPlay: false,
    recordAutoPlay: false,
    // 音频当前播放时间
    audioCurTime: 0,
    // 录音播放是否结束
    recorderPlayEnd: false
  },
  mutations: {
    // 更改第一个类型题
    changeFirstGame(state, bol) {
      state.isFirstGame = bol;
    },

    changeRecordEndStatus(state, bol) {
      state.recorderPlayEnd = bol;
    },

    changeAudioAutoPlay(state, bol) {
      state.audioAutoPlay = bol;
    },

    changeRecordAutoPlay(state, bol) {
      state.recordAutoPlay = bol;
    },

    changeVideoProgress(state, payload) {
      state.videoProgress = payload;
    },

    showLoading(state, payload) {
      state.loading = true;
      state.loadingTips = payload;
    },

    hideLoading(state) {
      state.loading = false;
    },

    // 更改第一个题型中的题
    changeFirstQuestion(state, bol) {
      state.isFirstQuestion = bol;
    },

    changeGirlLogo(state, girlLogoObj) {
      const { girlLogo } = girlLogoObj;
      state.girlLogo = girlLogo;
    },

    changeFontSize(state, sizeObj) {
      const { size } = sizeObj;
      state.fontSize = size;
    },

    resetRecorder(state) {
      if (state.recorder) {
        state.recorder.destroyEngine();
        state.recorder = null;
        state.recorderStatus = false;
        state.micAllowStatus = false;
      }
    },

    // 停止录音
    stopRecorder(state) {
      state.recorderStatus = false;
      state.recorder.stopMyRecorder();
    },

    // 开始录音
    startRecorder(state, payload) {
      this.commit('stopAudio');
      state.recordObj = payload;
      // 评测回调 getEvalMessage
      if (state.recorder && state.micAllowStatus) {
        state.recorder.startMyRecorder(payload.recordParams);
        state.recorderStatus = true;
        payload.startCallback && payload.startCallback();
        return;
      }
      //  如果没有录音机
      this.commit('showLoading', '初始化中');
      this.commit('initRecorder', { type: 'need' });
    },

    initRecorder(state, payload) {
      const { micFailCallback, micSuccessCallback, engineInitDone, type } =
        payload || {};
      const that = this;
      // 评测成功回调
      function engineBackResultDone(data) {
        state.recorderStatus = false;
        state.recordObj.stopCallback && state.recordObj.stopCallback();
        state.recordObj.getEvalMessage(JSON.parse(data));
      }

      // 麦克风被拒绝
      function micForbidCallback() {
        state.micAllowStatus = false;
        that.commit('hideLoading');
        if (micFailCallback) {
          micFailCallback();
        } else {
          showMyHint(
            '检查到电脑未带麦克风，后面说练习无法使用，您可以在电脑上插入带麦克功能的耳机解决问题！',
            () => {
              window.history.back();
            }
          );
        }
      }

      // 麦克风被允许
      function micAllowCallback() {
        micSuccessCallback && micSuccessCallback();
        state.micAllowStatus = true;
        that.commit('hideLoading');
        if (state.micAllowStatus && type === 'need') {
          that.commit('startRecorder', state.recordObj);
        }
      }

      // 初始化中回调
      function engineFirstInitDone() {
        engineInitDone && engineInitDone();
        state.engineInitStatus = true;
        if (state.micAllowStatus && type === 'need') {
          that.commit('startRecorder', state.recordObj);
        }
      }

      // JSS DK 不支持
      function JSSDKNotSupport() {
        showMyHint('暂时不支持该浏览器,建议更换浏览器', () => {
          window.history.back();
        });
      }

      // 如果没有就创建
      state.recorder = new RecorderObj[state.evalCompany]({
        elId: '',
        appKey: state.evalAppId,
        micForbidCallback: micForbidCallback,
        micAllowCallback: micAllowCallback,
        engineBackResultDone: engineBackResultDone,
        engineFirstInitDone: engineFirstInitDone,
        JSSDKNotSupport: JSSDKNotSupport
        // stopCallback: stopEvalCallback,
        // startCallback: startEvalCallback,
        // recordParams: state.recordObj.recordParams,
        // errorCallback: errorCallback,
        // successCallback: successCallback
      });
    },

    // 播放音频
    playAudio(state, payload) {
      state.audioAutoPlay = false;
      //  todo recordId 用于播放临时路径的地址
      const { src, index, type, endCallback } = payload;
      if (!state.currentAudio) {
        state.currentAudio = new Audio();
      }
      const audioSrc = src;
      state.currentAudio.src = handleWithUrl(audioSrc);
      state.currentAudio.play();

      state.currentAudio.onplay = () => {
        if (type === 'record') {
          state.playRecordStatus = true;
          state.playAudioStatus = false;
          state.playAudioIndex = -1;
          state.playRecordIndex = index;
          // 音频播放状态置为false
          state.recorderPlayEnd = false;
        } else {
          state.playRecordStatus = false;
          state.playAudioStatus = true;
          state.playAudioIndex = index;
          state.playRecordIndex = -1;
        }
      };

      state.currentAudio.oncanplay = () => {
        if (state.currentAudio) {
          state.audioDuration = state.currentAudio.duration;
        }
      };

      state.currentAudio.onended = () => {
        this.commit('stopAudio');
        if (type === 'record') {
          console.log('结束了');
          state.recorderPlayEnd = true;
        }
        endCallback && endCallback();
      };

      state.currentAudio.onstop = () => {
        this.commit('stopAudio');
      };

      state.currentAudio.ontimeupdate = () => {
        if (state.currentAudio) {
          const { duration, currentTime } = state.currentAudio;
          if (type === 'record') {
            console.log('record ontimeupdate');
          } else {
            state.audioCurTime = currentTime;
            state.audioPercent = (currentTime / duration) * 100 + '%';
          }
        }
      };
    },

    stopAudio(state) {
      if (state.currentAudio) {
        state.currentAudio.pause();
        state.currentAudio = null;
      }
      state.currentAudio = null;
      state.playAudioIndex = -1;
      state.playAudioStatus = false;
      state.playRecordIndex = -1;
      state.playRecordStatus = false;
      state.audioPercent = 0;
      state.audioCurTime = 0;
    }
  },
  actions: {
    // 考试题提交信息
    submitUnitUserAnswer({ state }, payload) {
      return saveUserGameTask({
        ...payload,
        // is_new  0说明不是第一道题, 1说明是第一道题
        is_new: state.isFirstQuestion && state.isFirstGame ? 1 : 0
      });
    }
  }
});
