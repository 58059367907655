function showInfo(msg1, msg2) {
  console.log(msg1, msg2);
}
import { formatRecordParams, getScoreObjByScore } from '../../utils/common';
import globalConfig from '../../store/global_config';

// 获取课文评测后渲染的文本以及颜色
export function getSTColorTextArr(readResult) {
  const { result, refText } = readResult;
  const arr = result.sentences || [];
  let newArr = [];
  arr.forEach(item => {
    const { details: sntDetails } = item;
    newArr = newArr.concat(sntDetails || []);
  });
  let startText = refText
    .replace(/\r\n/g, '#')
    .replace(/\n/g, '#')
    .replace(/ /g, '');
  // 如果有回车
  let startIndex = 0;
  const afterNewArr = [];
  newArr.forEach(innerItem => {
    const { overall, word: char } = innerItem;
    const hisColor = getScoreObjByScore(overall, globalConfig.state.scoreArr).color;
    let afterStr = startText.substr(startIndex, char.length);
    // 如果截取的字符串不等于评测字母
    const enterLength = afterStr.split('#').length - 1;
    if (afterStr !== char) {
      afterStr = startText.substr(startIndex, char.length + enterLength);
    }
    startIndex += char.length + enterLength;
    afterNewArr.push({
      itemText: afterStr.replace(/#/, '\n'),
      itemColor: hisColor
    });
  });
  return afterNewArr;
}

// 声通课文
export function getSTWordTextArr(readResult) {
  const newArr = [];
  console.log(newArr);
  return readResult;
}

export function createStRecorder({
  elId,
  appKey,
  secretKey,
  mode,
  recordParams = {},
  getEvalMessage,
  stopCallback,
  startCallback,
  changeRecorderStatus
}) {
  const myRecorder = new window._17kouyu.IRecorder({
    id: elId, //这里为HTML节点对应的id
    appKey: appKey || '17KouyuTestAppKey',
    secretKey: secretKey || '17KouyuTestSecretKey',
    /*1:flash模式, 2:html5模式, 3:html5优先,当浏览器不支持html5时自动切换为flash模式*/
    mode: mode || 2,
    onFlashLoad: function(code, message) {
      //Flash加载完成的回调，其他所有与录音机的操作必须在这个回调之后进行。
      //code值有：50000
      showInfo('录音机加载成功', message);
    },
    onConnectorStatusChange: function(code, message) {
      //连接器状态发生改变时回调
      //code值有：50100, 50103, 50104, 50109
      if (code == '50100') {
        showInfo('连接录音服务器成功', message);
      } else if (code == '50103' || code == '50104') {
        showInfo('连接录音服务器失败');
      } else if (code == '50109') {
        showInfo('开始连接录音服务器');
      }
    },
    onMicStatusChange: function(code, message) {
      //麦克风状态发生改变时回调。第一次加载Flash时也会触发该回调
      //code值有：50001, 50002, 50003
      if (code == '50001') {
        showInfo('可以访问麦克风', message);
        startRecorder();
      } else if (code == '50002') {
        showInfo('不能访问麦克风');
      } else if (code == '50003') {
        showInfo('没有检测到麦克风设备');
      }
    }
  });

  function startRecorder() {
    myRecorder.canRecord &&
      myRecorder.record({
        duration: recordParams.time, //录音时长，单位ms
        serverParams: formatRecordParams('ST', recordParams),
        // serverParams: {
        //   coreType: 'word.eval',
        //   refText: 'best',
        //   rank: 100,
        //   userId: 'guest'
        // },
        onRecordIdGenerated: (code, message) => {
          //服务器返回唯一的ID
          myRecorder.lastRecordId = message.recordId;
        },
        onStart: function() {
          //录音开始的回调
          showInfo('录音开始');
          startCallback && startCallback();
          changeRecorderStatus && changeRecorderStatus();
        },
        onStop: function() {
          //到达指定时间，录音自动停止的回调
          showInfo('录音自动停止');
          stopCallback && stopCallback();
          getLastScore();
        }
      });
  }

  myRecorder.stopMyRecorder = () =>
    myRecorder.stop({
      onStop: function() {
        //录音手动停止的回调
        showInfo('录音手动停止');
        stopCallback && stopCallback();
        getLastScore();
      }
    });

  function getLastScore() {
    if (myRecorder.lastRecordId != '') {
      myRecorder.getScores({
        recordId: myRecorder.lastRecordId, //指定的录音ID
        success: function(data) {
          //评分获得后回调。这里可能是评分成功，或者评分出错
          showInfo('获取评分成功：' + JSON.stringify(data));
          const hisData = data[myRecorder.lastRecordId];
          getEvalMessage(hisData);
          myRecorder.lastRecordId = '';
        }
      });
    } else {
      showInfo('请先录音再获取评分');
    }
  }

  return myRecorder;
}
