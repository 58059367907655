import { getScoreObjByScore } from '../../utils/common';
import globalConfig from '../../store/global_config';
// 获取课文评测后渲染的文本以及颜色
export function getXSColorTextArr(readResult, rank = 100) {
  const {
    result: { details },
    params
  } = readResult;
  const arr = details || [];
  let newArr = [];
  arr.forEach(item => {
    const { snt_details: sntDetails } = item;
    newArr = newArr.concat(sntDetails || []);
  });
  let startText = params.request.refText
    .replace(/\r\n/g, '#')
    .replace(/\n/g, '#')
    .replace(/ /g, '');
  // 如果有回车
  let startIndex = 0;
  const afterNewArr = [];
  newArr.forEach((innerItem, itemIndex) => {
    const { score, char } = innerItem;
    const hisColor = getScoreObjByScore(
      (score / rank) * 100,
      globalConfig.state.scoreArr
    ).color;
    let afterStr = startText.substr(startIndex, char.length);
    // 如果截取的字符串不等于评测字母
    const enterLength = afterStr.split('#').length - 1;
    if (afterStr !== char) {
      afterStr = startText.substr(startIndex, char.length + enterLength);
    }
    startIndex += char.length + enterLength;
    afterNewArr.push({
      itemText: afterStr.replace(/#/, '\n'),
      itemColor: hisColor,
      itemKey: `${itemIndex}`
    });
  });
  return afterNewArr;
}

// 单词评测数组会少字段，例如评测in the water 时候返回的 数据结构如下
/**
 *  result:{
 *  details:[
 *  {
 * phone:[{},{}]
 * },
 * {phone: [{},{},{}]},
 * {phone: [{},{},{},{}]} --这里只有四个，少了r的值,不排除评测时候返回字段少于单词字段
 * ]
 * }
 */
export function getXSWordTextArr(readResult, rank = 100) {
  const { refText, result } = readResult;
  let colorNewArr = [];
  result.details.forEach(resultItem => {
    const phoneArr = resultItem.phone || [];
    const newArr = [];
    phoneArr.forEach(phoneItem => {
      if (phoneItem.phid) {
        const hisIndexArr = phoneItem.phid.split('_');
        const hisScore = (phoneItem.score / rank) * 100;
        const hisColor = getScoreObjByScore(
          hisScore,
          globalConfig.state.scoreArr
        ).color;
        hisIndexArr.forEach(indexItem => {
          newArr[indexItem - 1] = hisColor || 'inherit';
        });
      }
    });
    newArr.push('');
    colorNewArr = colorNewArr.concat(newArr);
  });
  colorNewArr.pop();
  const afterTextArr = [];
  refText.split('').forEach((refItem, refIndex) => {
    afterTextArr.push({
      itemColor: colorNewArr[refIndex] || 'inherit',
      itemKey: `${refIndex}_${refItem}`,
      itemText: refItem || ''
    });
  });
  return afterTextArr;
}

export function createXSRecorder({
  appKey,
  engineBackResultDone,
  micForbidCallback,
  micAllowCallback,
  engineFirstInitDone
}) {
  // 获取元素
  const myRecorder = new window.EngineEvaluat({
    applicationId: appKey || 'a419',
    // applicationId: '请上传分配给商户的appkey',
    // 健康检查最长超时时间
    maxHealthyCheckTime: 5000,

    micAllowCallback: function() {
      console.log('麦克风已经允许');
      micAllowCallback && micAllowCallback();
    },
    micForbidCallback: function() {
      console.log('麦克风没有被允许');
      micForbidCallback && micForbidCallback();
    },
    playAudioComplete: function() {
      console.log('播放完毕');
    },
    playAudioError: function() {
      console.log('播放出错');
    },
    engineBackResultDone: function(msg) {
      engineBackResultDone && engineBackResultDone(msg);
    },
    engineBackResultFail: function(msg) {
      console.log('评测失败');
      console.log(msg);
    },
    engineFirstInitDone: function(msg) {
      console.log('初始化成功');
      console.log(msg);
      engineFirstInitDone && engineFirstInitDone();
    },
    micVolumeCallback: function(data) {
      console.log('录音音量大小为:' + data);
    },
    JSSDKNotSupport: function() {
      alert('jssdk  not support');
    },

    getRealTimeAudioBuffer: function(audioBuffer) {
      console.log(audioBuffer);
    }
  });

  myRecorder.startMyRecorder = myRecorder.startRecord;

  myRecorder.stopMyRecorder = myRecorder.stopRecord;

  return myRecorder;
}
