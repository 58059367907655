import { notification, Modal } from 'ant-design-vue';
import globalConfig from '@/store/global_config';
import { wordColorObj, textColorObj } from '../utils/Recorder/index';
import { returnRequest } from '../api/service';

// 根据分数获得颜色和great等
export function getScoreObjByScore(score = 0, arr = []) {
  let scoreObj = {};
  arr.forEach(item => {
    if (score >= item.min && score <= item.max) {
      scoreObj = { ...item, hisScore: score };
    }
  });
  return scoreObj;
}

export const PUBLIC_DATA = {
  imgUrl: process.env.VUE_APP_IMG_URL,
  defaultRecordTime: 5,
  defaultRecordUrl:
    'https://yyashow-1255660808.cos.ap-beijing.myqcloud.com/english-study/play/start_record.mp3',
  indexPrefix: {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D',
    4: 'E',
    5: 'F',
    6: 'G',
    7: 'H',
    8: 'F'
  }
};

export function getWordColorText(result) {
  return wordColorObj[globalConfig.state.evalCompany](result);
}

export function getColorTextArr(result, rank = 100) {
  return textColorObj[globalConfig.state.evalCompany](result, rank);
}

// 作者：多拉斯基
// 来源：CSDN
// 原文：https://blog.csdn.net/duola8789/article/details/78749917
// 版权声明：本文为博主原创文章，转载请附上博文链接！
/**
 *打乱一个数组中的元素, 注意：会更改原数组
 * @param {*} arr
 */
export function shuffle(arr) {
  let length = arr.length;
  let randomIndex;
  let temp;
  while (length) {
    randomIndex = Math.floor(Math.random() * length--);
    temp = arr[randomIndex];
    arr[randomIndex] = arr[length];
    arr[length] = temp;
  }
  return arr;
}

export function showMyToast(type, message) {
  const typeObj = {
    s: 'success',
    w: 'warning',
    i: 'info',
    e: 'error',
    o: 'open',
    c: 'close'
  };
  const toastType = message ? typeObj[type] || type : 'success';
  const toastMessage = message || type;
  notification[toastType]({
    message: toastMessage
  });
}

// 获取token
export function getToken() {
  return localStorage.getItem('token') ? localStorage.getItem('token') : '';
}
// 设置token
export function setToken(token) {
  return localStorage.setItem('token', token);
}

export function getHash() {
  return localStorage.getItem('hash-key')
    ? localStorage.getItem('hash-key')
    : '';
}

export function setCompany(value) {
  return localStorage.setItem('user_company', value);
}

export function getCompany() {
  return localStorage.getItem('user_company') || 'yidianchuzhong';
}

// 设置token
export function setHash(hashKey) {
  return localStorage.setItem('hash-key', hashKey);
}
// 设置用户信息
export function setUserInfo(userInfo) {
  return localStorage.setItem('user_info', userInfo);
}

export function getUserInfo() {
  return localStorage.getItem('user_info');
}

export function showLoading(text) {
  globalConfig.commit('showLoading', text);
}

export function hideLoading() {
  globalConfig.commit('hideLoading');
}

export function handleWithUrl(url) {
  const { imgUrl } = PUBLIC_DATA;
  return url.indexOf('http') === -1 ? imgUrl + url : url.replace(':8080', '');
}

export function formatTime(s) {
  let minute = Math.floor(s / 60) % 60;
  let second = Number(s % 60).toFixed(2);
  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;
  return minute + ':' + second;
}

/**
 * 获得某个颜色变淡后的颜色
 * @param {*} color 颜色值
 * @param {*} level 变淡的程度
 */

const HexToRgb = function(str) {
  // test方法检查在字符串中是否存在一个模式，如果存在则返回true，否则返回false
  // replace替换查找的到的字符串
  str = str.replace('#', '');
  // match得到查询数组
  var hxs = str.match(/../g);
  for (var i = 0; i < 3; i++) hxs[i] = parseInt(hxs[i], 16);
  return hxs;
};

const RgbToHex = function(a, b, c) {
  var hexS = [a.toString(16), b.toString(16), c.toString(16)];
  for (var i = 0; i < 3; i++) {
    if (hexS[i].length === 1) hexS[i] = '0' + hexS[i];
  }
  return '#' + hexS.join('');
};

export function getLightColor(color, level = 0.5) {
  var myRgb = HexToRgb(color);
  for (var i = 0; i < 3; i++) {
    myRgb[i] = Math.floor((255 - myRgb[i]) * level + myRgb[i]);
  }
  return RgbToHex(myRgb[0], myRgb[1], myRgb[2]);
}

export function handleWithSTParams(payload) {
  const { coreType, precision, qType, refText } = payload;
  switch (coreType) {
    case 'para':
      return {
        coreType: 'para.eval',
        refText,
        precision: precision || 1
      };
    case 'sent':
      return {
        coreType: 'sent.eval'
      };
    case 'word':
      return {
        coreType: 'word.eval'
      };
    case 'align':
      return {
        coreType: 'align.eval'
      };
    case 'choice':
      return {
        coreType: 'choice.rec'
      };
    case 'open':
      return {
        coreType: 'align.eval',
        ...handleWithSTOpen(qType)
      };
    default:
      return {};
  }
}

export function handleWithSTOpen(qType) {
  // 0 短文朗读;1 短文跟读;2 句子翻译;3 段落翻译;4 故事复述;5 看图说话; 6 情景问答;7 口头作文，
  switch (qType) {
    case 'scene':
      return {
        qType: 6
      };
    case 'retell':
      return {
        qType: 4
      };
    default:
      return {};
  }
}

export function formatRecordParams(type, payload) {
  // const { coreType, precision, qType, refText, scale } = payload;
  console.log(payload);
  /*
    coreType: "open"
    key: [{…}]
    lm: [{…}]
    phdet: 1
    qtype: "scene"
    quest_ans: "Who usually does the chores in your home?"
    rank: 10
    score: 10
    syldet: 1
    syllable: 1
    symbol: 1
    time: 10000
  */
  // const SHENG_TONG = {
  //   coreTypeObj: {
  //     // 段落评测
  //     para: 'para.eval',
  //     // 句子评测
  //     sent: 'sent.eval',
  //     // 单词评测
  //     word: 'word.eval',
  //     // 开放题
  //     open: 'open.eval',
  //     // 音频对比
  //     align: 'align.eval',
  //     // 有限分支识别(选择题)
  //     choice: 'choice.rec'
  //   },
  //   // open.eval 题型必须，0短文朗读;1短文跟读;2句子翻译;3段落翻译;4故事复述;5看图说话; 6情景问答;7口头作文，
  //   qTypeObj: {
  //     // 情景问答
  //     scene: 6,
  //     // 故事复述
  //     retell: 4
  //   }
  // };
  switch (type) {
    // 声通
    case 'ST':
      return {
        ...handleWithSTParams(payload),
        attachAudioUrl: 1,
        // 分制，默认 100，取值范 围(0-100],影响范围包 括各评分维度分数
        scale: payload.scale,
        userId: 'guest',
        paragraph_need_word_score: 1
      };
    // return {
    //   // 指定评分内核类型 详见”返回结果及请求参 数.pdf”
    //   coreType: SHENG_TONG.coreTypeObj[coreType],
    //   refText,
    //   attachAudioUrl: 1,
    //   getParam: 1,
    //   // 题型，open.eval 题型必须，0 短文朗读;1 短文跟读;2 句子翻译;3 段落 翻译;4 故事复述;5 看图说话; 6 情景问答;7 口头作文，
    //   // 开放题型建议 5 个以上参考答案，越多 越准,用竖线|隔开
    //   qType: SHENG_TONG.qTypeObj[qType],
    //   // 分制，默认 100，取值范 围(0-100],影响范围包 括各评分维度分数
    //   scale: scale,
    //   // 得分精度，默认 1，建议 取值的范围(0,1]
    //   precision: precision || 1,
    //   userId: 'guest',
    //   paragraph_need_word_score: 1
    // };
    // 先声
    case 'XS':
      return {};
    default:
      return payload;
  }
}

export function testAutoPlay() {
  // 返回一个promise以告诉调用者检测结果
  return new Promise(resolve => {
    let audio = document.createElement('audio');
    // require一个本地文件，会变成base64格式
    audio.src =
      'https://yyashow-1255660808.cos.ap-beijing.myqcloud.com/yyashow-teacher/empty.mp3';
    document.body.appendChild(audio);
    let autoplay = true;
    // play返回的是一个promise
    audio
      .play()
      .then(() => {
        // 支持自动播放
        autoplay = true;
      })
      .catch(() => {
        // 不支持自动播放
        autoplay = false;
      })
      .finally(() => {
        audio.remove();
        // 告诉调用者结果
        resolve(autoplay);
      });
  });
}

export function checkMicroPhone() {
  return new Promise((resolve, reject) => {
    window.navigator.mediaDevices
      .getUserMedia({
        audio: true
      })
      .then(() => {
        resolve();
      })
      .catch(err => {
        reject('授权失败！');
        console.log('错误：', err);
        // 如果用户电脑没有麦克风设备或者用户拒绝了，或者连接出问题了等
        // 这里都会抛异常，并且通过err.name可以知道是哪种类型的错误
      });
  });
}

export function getStorageItem(item) {
  return window.localStorage.getItem(item) || '';
}

export function setStorageItem(item, value) {
  return window.localStorage.setItem(item, value);
}

export function getStartRecordObj(coreType, evalTime, evalText) {
  const currentCoreType = {
    word: 'en.word.score', // 单词
    sent: 'en.sent.score', // 短语
    pred: 'en.pred.score', // 段落
    choc: 'en.choc.score', // 表示英文选择题题型'
    pche: 'en.pche.score', // 表示英文扩展选择题',
    pcha: 'en.pcha.score', // 表示选择句子阅读评分题型
    pqan: 'en.pqan.score', // 表示英文问答题题型
    pict: 'en.pict.score', // 表示英文看图作文题型
    retell: 'en.retell.score', //  表示英文故事复述题型
    alpha: 'en.alpha.score' // 表示英文音标评测
  };
  return {
    coreType: currentCoreType[coreType],
    evalTime: evalTime,
    refText: evalText,
    attachAudioUrl: 1,
    phdet: 1,
    syllable: 1,
    syldet: 1,
    symbol: 1,
    precision: 0.1,
    outputPhones: 1
  };
}

export function showMyHint(title, okCallback, otherOptions) {
  Modal.error({
    title: title,
    cancelText: '取消',
    centered: true,
    okText: '确定',
    onOk: () => {
      okCallback && okCallback();
    },
    ...otherOptions
  });
}
export function showMySuccess(title, okCallback, otherOptions) {
  Modal.success({
    title: title,
    cancelText: '取消',
    centered: true,
    okText: '确定',
    onOk: () => {
      okCallback && okCallback();
    },
    ...otherOptions
  });
}

export function showMyModal(title, okCallback, cancelCallback, otherOptions) {
  Modal.confirm({
    title: title,
    centered: true,
    cancelText: '取消',
    okText: '确定',
    onOk() {
      okCallback && okCallback();
    },
    onCancel() {
      cancelCallback && cancelCallback();
    },
    ...otherOptions
  });
}
export const indexToUpper = {
  0: '一',
  1: '二',
  2: '三',
  3: '四',
  4: '五',
  5: '六',
  6: '七',
  7: '八',
  8: '九',
  9: '十',
  10: '十一',
  11: '十二',
  12: '十三'
};

export const indexPrefix = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'F'
};

export function getData(method, url, param) {
  return returnRequest(method, url, param);
}
export function createAudioText() {
  return new Audio();
}

export function showError(text) {
  showMyToast('w', text);
}

// 根据时间获取时分秒
export function formatTimeByTime(time) {
  let minute = Math.floor(time / 60);
  minute = minute < 10 ? `0${minute}` : minute;
  let second = Math.floor(time % 60);
  second = second < 10 ? `0${second}` : second;
  return `${minute}:${second}`;
}

export default {
  PUBLIC_DATA: PUBLIC_DATA,
  showMyToast,
  getToken,
  setToken,
  getHash,
  setHash,
  imgUrl: process.env.VUE_APP_IMG_URL,
  handleWithUrl,
  formatTime,
  testAutoPlay,
  getStorageItem,
  setStorageItem,
  getStartRecordObj,
  createAudioText: new Audio(),
  showMyHint: showMyHint,
  showMyModal: showMyModal,
  getLightColor,
  indexToUpper,
  indexPrefix
};
